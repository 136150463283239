import logo from './logo.svg';
import './App.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home/Home.jsx';
import ContactUs from './pages/Contact/Contact.jsx';
import ForConsumerPage from './pages/ForConsumer';
import ForArtistPage from './pages/ForArtist';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage.jsx';
import PrivacyPolicy from './pages/PrivacyPolicy/index.jsx';
import TermsAndCondition from './pages/TermsAndCondition/index.jsx'
import LicenseAgreement from './pages/LicenseAgreement/index.jsx'
import HeaderWrapper from '../src/components/headerwrapper/index.js'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HeaderWrapper child={<Home/>} />}> </Route>
        <Route path="/ContactUs" element={<ContactUs />}> </Route>
        <Route path="/forConsumerPage" element={<HeaderWrapper child={<ForConsumerPage />} /> }> </Route>
        <Route path="/forArtistPage" element={<HeaderWrapper child={<ForArtistPage />} /> }> </Route>
        <Route path="/privacy-policy" element={<HeaderWrapper child={<PrivacyPolicy />} /> }> </Route>
        <Route path="/terms-and-conditions" element={<HeaderWrapper child={<TermsAndCondition />} /> }> </Route>
        <Route path="/license-agreement" element={<HeaderWrapper child={<LicenseAgreement />} /> }> </Route>
        <Route path="*" element={<HeaderWrapper child={<NotFoundPage />} />}> </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;


import './ForRoleMainSection.css'

import ForRole from '../../components/ForRoleComponent'
function ForRoleMainSection({title,role,description,content,roleColor}){


    return (<div className='forConsumerMainSection'>
<div className='forConsumerMainSection__header'>
<div  className='forConsumerMainSection__left'>
    <ForRole title={role}  backgorundColor={roleColor}/>
<p className='getStartedBox__title'>{title}</p>

</div>

<div className='forConsumerMainSection__right'>
    <p>{content}</p>
</div>

</div>
<div  className='getStartedBox__button'>   <p>Get Started</p> </div>
        
    </div>);
}


export default ForRoleMainSection;
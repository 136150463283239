import './HomeMainSection.css';
import React from 'react';
import {
  homepage_frame_1,
  homepage_frame_2,
  homepage_frame_3,
  homepage_frame_4,
  homepage_frame_5,
  homepage_frame_6
} from '../../../assets/homepage/index';

const HomeMainSection = () => {

   
  return  (
        <div className='homeMainSection'>
      
          {/* Left Section */}
          <div className='homeMainSection__left'>
            <div className='getStartedBox'>
              <p className='home-getStartedBox__title'>
                Discover and stream<br />stunning Art on your TV
              </p>
              <p className='home-getStartedBox__subtitle'>
                At PiXLMe, we believe in making beautiful art accessible to<br />
                everyone. Discover, curate, and stream high-quality art<br />
                collections to your TV and other display devices,<br />
                transforming your space into a dynamic art gallery.
              </p>
              <div className='getStartedBox__button'>
                <p>Get Started</p>
              </div>
            </div>
          </div>
      
          {/* Right Section */}
          <div className='homeMainSection__right'>
      
            {/* Image Containers */}
            <div className='homeMainSection_images_container'>
              <img src={homepage_frame_1} className='homepage_frame_1 slideInLeft' alt="Art frame 1" />
              <img src={homepage_frame_4} className='homepage_frame_4' alt="Art frame 4" />
            </div>
            <div className='homeMainSection_images_container'>
              <img src={homepage_frame_2} className='homepage_frame_2' alt="Art frame 2" />
              <img src={homepage_frame_5} className='homepage_frame_5 slideInBottom' alt="Art frame 5" />
            </div>
            <div className='homeMainSection_images_container'>
              <img src={homepage_frame_3} className='homepage_frame_3 slideInBottom' alt="Art frame 3" />
              <img src={homepage_frame_6} className='homepage_frame_6' alt="Art frame 6" />
            </div>
      
          </div>
        </div>
      )
};

export default HomeMainSection;

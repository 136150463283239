import React from 'react';
import './LicenseAgreement.css';

function LicenseAgreement() {
  return (
    <div className='eula'>
      <h1>End User License Agreement (EULA) for PIXLME</h1>
 
      <p>This End User License Agreement ("Agreement") is a legal agreement between you ("User" or "you") and PIXLME ("we," "our," or "us") governing your use of the PIXLME application ("App"). By downloading, installing, or using the App, you agree to be bound by the terms of this Agreement.</p>

      <h2>1. License Grant</h2>
      <p>PIXLME grants you a limited, non-exclusive, non-transferable, revocable license to use the App for personal, non-commercial purposes, subject to the terms of this Agreement.</p>

      <h2>2. Eligibility</h2>
      <p>You must be at least 13 years old to use the App. By using the App, you represent and warrant that you meet this age requirement.</p>

      <h2>3. Account Registration</h2>
      <p>To access certain features of the App, you may need to create an account. You agree to provide accurate and complete information during the registration process and to keep this information updated. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>

      <h2>4. Subscription and Payments</h2>
      <ul>
        <li><strong>Subscription Plans:</strong> PIXLME offers various subscription plans that provide access to different features and content. Details of the subscription plans and associated fees are available within the App.</li>
        <li><strong>Payment:</strong> Subscription fees are billed in advance on a monthly or annual basis. By subscribing, you authorize PIXLME to charge the applicable subscription fees to your designated payment method.</li>
        <li><strong>Cancellation:</strong> You may cancel your subscription at any time. Cancellation will take effect at the end of the current billing period, and you will not be charged for the next period. Subscription fees are non-refundable except as required by law.</li>
      </ul>

      <h2>5. Content</h2>
      <ul>
        <li><strong>User Content:</strong> Users may upload, share, and stream photographs through the App. By uploading content, you grant PIXLME a worldwide, non-exclusive, royalty-free license to use, display, and distribute your content within the App.</li>
        <li><strong>Ownership:</strong> You retain all rights to your content. PIXLME does not claim ownership of any content you upload.</li>
        <li><strong>Prohibited Content:</strong> You agree not to upload content that is unlawful, harmful, or violates the rights of others. PIXLME reserves the right to remove any content that violates these terms.</li>
      </ul>

      <h2>6. Intellectual Property</h2>
      <p>The App and all related content and materials are the property of PIXLME and its licensors. You agree not to reproduce, modify, or distribute any part of the App without prior written permission from PIXLME.</p>

      <h2>7. Prohibited Conduct</h2>
      <ul>
        <li>Use the App for any unlawful or unauthorized purpose.</li>
        <li>Interfere with or disrupt the operation of the App or servers.</li>
        <li>Attempt to gain unauthorized access to any part of the App or other accounts.</li>
        <li>Use the App to infringe upon the rights of others.</li>
      </ul>

      <h2>8. Termination</h2>
      <p>PIXLME reserves the right to terminate or suspend your access to the App at any time, with or without cause, and without notice. Upon termination, your right to use the App will immediately cease.</p>

      <h2>9. Disclaimer of Warranties</h2>
      <p>The App is provided "as is" and "as available" without warranties of any kind, either express or implied. PIXLME disclaims all warranties, including but not limited to, warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>

      <h2>10. Limitation of Liability</h2>
      <p>In no event shall PIXLME be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to, loss of profits, data, use, goodwill, or other intangible losses, resulting from your use of the App.</p>

      <h2>11. Governing Law</h2>
      <p>This Agreement shall be governed by and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law principles.</p>

      <h2>12. Changes to This Agreement</h2>
      <p>PIXLME reserves the right to modify this Agreement at any time. Any changes will be effective immediately upon posting the updated Agreement within the App. Your continued use of the App after any changes constitutes your acceptance of the new terms.</p>

      <h2>13. Contact Information</h2>
      <p>If you have any questions or concerns about this Agreement, please contact us at <a href="mailto:support@pixlme.com">support@pixlme.com</a>.</p>
    </div>
  );
}

export default LicenseAgreement;

import React, { useState } from "react";
import "./Header.css";
import logo from '../../assets/pixlme.webp';
import { FaChevronDown } from "react-icons/fa";
import { Link } from 'react-scroll';
import { CgChevronRight } from "react-icons/cg";
import { artistFlow } from "../../constants/linkscontants";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isUserRoleOpen, setIsUserRoleOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleUserRoleOpen = () => {
    setIsUserRoleOpen(!isUserRoleOpen);
  };

  return (
    <header className="header">
      <a href="/" className="logo">
        <img src={logo} alt="Logo" />
      </a>

      <nav className={`nav ${isOpen ? "open" : ""}`}>
        <a href="/">Home</a>

        <div className="navbar-items" onClick={toggleUserRoleOpen}>
          <span className="item">How it works</span>
          <FaChevronDown className="navbar-items-icon" />
          {isUserRoleOpen && (
            <div className={`dropdown-menu ${isUserRoleOpen ? "open" : ""}`}>
              <a href="/forConsumerPage" className="dropdown-item">
                For Consumer <CgChevronRight />
              </a>
              <a href="/forArtistPage" className="dropdown-item">
                For Artist <CgChevronRight />
              </a>
            </div>
          )}
        </div>

        <a href="/forConsumerPage" className="responsive-item">For Consumer</a>
        <a href="/forArtistPage" className="responsive-item">For Artist</a>

       
        <Link to="features" smooth={true} duration={1300} className="nav-link">Features</Link>
        <Link to="artists" smooth={true} duration={1300} className="nav-link">Artists</Link>
        <Link to="pricing" smooth={true} duration={1300} className="nav-link">Pricing</Link>

        <div className="responsive-item">
          <button className="sign-up"><a href={artistFlow}>Sign up</a> </button>
        </div>
        <div className="responsive-item">
          <button className="login"><a href={artistFlow}>Login</a></button>
        </div>
      </nav>

      <div className="auth-buttons">
        <button className="sign-up"><a href={artistFlow} className="sign-up">Sign up</a></button>
        <button className="login"><a href={artistFlow} className="login">Login</a> </button>
      </div>

      <div className="hamburger" onClick={toggleMenu}>
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </div>
    </header>
  );
};

export default Header;

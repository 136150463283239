import './ForArtist.css'
import ForRoleMainSection from '../../components/ForRoleMainSection';



import { artistBenefitsArray,artistOpportunitiesArray ,artistGuide,artistKeyFeatures} from '../../constants/arrayconstants';
import Benefits from '../../components/Benefits';
import StepByGuide from '../../components/StepByGuideComponent';
import KeyFeatureComponent from '../../components/KeyFeatureComponent';

import { artistMainImage } from '../../assets/artist';
import Testimonials from '../../components/Testimonials';
import Faq from '../../components/faq';
function ForArtistPage(){

    return(<div   className='forConsumerPage'> 

<ForRoleMainSection 
title={<p>PiXLMe’s mission to bring<br/>
    beautiful art to everyday screens</p>} 
role='For Artist' 
roleColor="#62DF57"
content='Showcase your Art - Upload, categorize and share your work with a global audience. Gain visibility and potential revenue'
/>
<img  src={artistMainImage}  alt='artist home image' className='forArtistshowcase'/>
  <Benefits title="Benefits" children={ artistBenefitsArray}/>  
  <KeyFeatureComponent keyFeatures={artistKeyFeatures}/>
  <Benefits title="Opportunities" children={ artistOpportunitiesArray}/>  
  <StepByGuide guideChildren={artistGuide} />
    <Testimonials user="artist"/>
    <Faq/>
    </div>)
}

export default ForArtistPage;
import './StreamArt.css';

import googleButton from '../../assets/googlebutton.png';
import appStoreButton from '../../assets/appstorebutton.png';
import streamArt from '../../assets/streamArt.png';
import { playStoreLink, appStoreLink } from '../../constants/linkscontants';

const StreamArt = () => {
    return (
        <div className="streamArt-section">
            <div className="streamArt-section__left">
                <span className='streamArt-section__title_1'>
                    Stream your art<br />
                    <span className='streamArt-section__title_2'>
                        anywhere anytime<br />on your TV
                    </span>
                </span>

                <div className='StreamArt__appButtons'>
                    <a href={playStoreLink} target="_blank" rel="noopener noreferrer">
                        <img src={googleButton} alt='Google Play button' className='StreamArt__appButton' />
                    </a>

                    <a href={appStoreLink} target="_blank" rel="noopener noreferrer">
                        <img src={appStoreButton} alt='App Store button' className='StreamArt__appButton' />
                    </a>
                </div>
            </div>

            <img src={streamArt} alt="Stream Art" className="streamArt-section__right" />
        </div>
    );
};

export default StreamArt;

import './PrivacyPolicy.css';

function PrivacyPolicy() {
  return (
    <div className='privacy-policy'>
      <h1>Privacy Policy</h1>

      <p>Welcome to PIXLME, a photo-sharing application that allows consumers to stream photographs to their TV screens. Your privacy is important to us, and we are committed to protecting your personal data. This Privacy Policy explains how we collect, use, and share information about you when you use our app.</p>

      <h2>1. Information We Collect</h2>
      <p>We collect information from you when you use PIXLME, including:</p>
      <ul>
        <li><strong>Personal Information:</strong> When you create an account, we collect information such as your name, email address, and payment details.</li>
        <li><strong>Usage Information:</strong> We collect information about how you interact with our app, including the features you use, the content you view, and the actions you take.</li>
        <li><strong>Device Information:</strong> We collect information about the device you use to access PIXLME, including the device model, operating system, and unique device identifiers.</li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>We use the information we collect for the following purposes:</p>
      <ul>
        <li><strong>To Provide and Improve Our Services:</strong> We use your information to operate and enhance the functionality of PIXLME, including personalizing your experience and providing customer support.</li>
        <li><strong>To Process Payments:</strong> We use your payment information to process subscription fees and other transactions.</li>
        <li><strong>To Communicate with You:</strong> We use your contact information to send you updates, newsletters, and promotional materials. You can opt out of these communications at any time.</li>
        <li><strong>To Ensure Security:</strong> We use your information to protect the security of our app and users, including detecting and preventing fraud and unauthorized access.</li>
      </ul>

      <h2>3. How We Share Your Information</h2>
      <p>We do not share your personal information with third parties except as described in this Privacy Policy or with your consent. We may share information in the following circumstances:</p>
      <ul>
        <li><strong>Service Providers:</strong> We may share your information with third-party service providers who help us operate and improve PIXLME. These providers are required to protect your information and use it only for the purposes we specify.</li>
        <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to a legal request, such as a subpoena or court order.</li>
        <li><strong>Business Transfers:</strong> If we are involved in a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction. We will notify you of any such change in ownership or control of your personal information.</li>
      </ul>

      <h2>4. Your Choices</h2>
      <p>You have several options regarding your information:</p>
      <ul>
        <li><strong>Account Information:</strong> You can update or delete your account information at any time through the app settings.</li>
        <li><strong>Marketing Communications:</strong> You can opt out of receiving promotional emails from us by following the instructions in those emails. If you opt out, we may still send you non-promotional communications, such as those about your account or our ongoing business relations.</li>
        <li><strong>Cookies:</strong> Most web browsers are set to accept cookies by default. You can adjust your browser settings to remove or reject cookies, but this may affect your ability to use some features of PIXLME.</li>
      </ul>

      <h2>5. Data Security</h2>
      <p>We implement reasonable security measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>

      <h2>6. Children's Privacy</h2>
      <p>PIXLME is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete that information.</p>

      <h2>7. Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date. You are advised to review this Privacy Policy periodically for any changes.</p>

      <h2>8. Contact Us</h2>
      <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:support@pixlme.com">support@pixlme.com</a>.</p>

      <p>By using PIXLME, you acknowledge that you have read, understood, and agree to this Privacy Policy. Thank you for trusting PIXLME with your information!</p>
    </div>
  );
}

export default PrivacyPolicy;

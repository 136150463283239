import './Benefits.css';

function Benefits({ title, children }) {
    return (
        <div className="benfitsSection">
            <p className='benfitsSection__title'>{title}</p>
            <div className="benefits">
                {children.map((benefit, index) => {
                    const BenefitIcon = benefit.icon;
                    return (
                        <div key={index} className="benefitsCard"> {/* Added unique key here */}
                            <BenefitIcon className='benefitIcon' fill='white' />
                            <h3 className='benefitTitle'>{benefit.title}</h3>
                            <p className='benefitDescription'>{benefit.description}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default Benefits;

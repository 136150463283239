import React from 'react';
import './TermsAndCondition.css';

function TermsAndCondition() {
  return (
    <div className='terms-and-conditions'>
      <h1>Terms and Conditions</h1>

      <p>Welcome to PIXLME, a photo-sharing application that allows consumers to stream photographs to their TV screens. By using the PIXLME app, you agree to the following terms and conditions. Please read them carefully.</p>

      <h2>1. Acceptance of Terms</h2>
      <p>By downloading, installing, or using the PIXLME app, you agree to be bound by these Terms and Conditions. If you do not agree to these terms, please do not use the app.</p>

      <h2>2. Description of Service</h2>
      <p>PIXLME provides a platform where users can subscribe to stream photographs uploaded by artists and photographers. The service is available on a subscription basis, offering different plans and features.</p>

      <h2>3. Subscription Model</h2>
      <ul>
        <li><strong>Free Tier:</strong> Access to a limited selection of photographs with advertisements.</li>
        <li><strong>Premium Tier:</strong> Ad-free access to the full library of photographs for a monthly or annual subscription fee.</li>
      </ul>

      <h2>4. Account Registration</h2>
      <ul>
        <li>Users must create an account to use PIXLME.</li>
        <li>You agree to provide accurate and complete information during the registration process.</li>
        <li>You are responsible for maintaining the confidentiality of your account credentials.</li>
      </ul>

      <h2>5. User Responsibilities</h2>
      <ul>
        <li>You must be at least 13 years old to use PIXLME.</li>
        <li>You agree not to use PIXLME for any illegal or unauthorized purpose.</li>
        <li>You agree not to interfere with or disrupt the app or servers connected to the app.</li>
      </ul>

      <h2>6. Content and Intellectual Property</h2>
      <ul>
        <li>All photographs uploaded to PIXLME are owned by the respective artists/photographers.</li>
        <li>Users are granted a limited, non-exclusive, non-transferable license to stream the photographs for personal, non-commercial use.</li>
        <li>You may not copy, modify, or distribute any content from PIXLME without prior written permission from the content owner.</li>
      </ul>

      <h2>7. Payments and Refunds</h2>
      <ul>
        <li>Subscription fees are billed in advance on a monthly or annual basis.</li>
        <li>All payments are non-refundable except as required by law or at PIXLME's discretion.</li>
        <li>PIXLME reserves the right to change the subscription fees at any time. Any changes will be communicated to you in advance.</li>
      </ul>

      <h2>8. Termination</h2>
      <ul>
        <li>PIXLME reserves the right to suspend or terminate your account at any time for any reason, including violation of these Terms and Conditions.</li>
        <li>Upon termination, your right to use the app will immediately cease.</li>
      </ul>

      <h2>9. Privacy Policy</h2>
      <p>PIXLME respects your privacy and is committed to protecting your personal data. Please review our Privacy Policy for more information.</p>

      <h2>10. Limitation of Liability</h2>
      <p>PIXLME is provided "as is" and "as available" without warranties of any kind. PIXLME will not be liable for any damages arising from the use of or inability to use the app.</p>

      <h2>11. Changes to Terms</h2>
      <ul>
        <li>PIXLME reserves the right to modify these Terms and Conditions at any time. Any changes will be effective immediately upon posting the updated terms on the app.</li>
        <li>Your continued use of the app after any changes constitutes your acceptance of the new terms.</li>
      </ul>

      <h2>12. Contact Information</h2>
      <p>For any questions or concerns regarding these Terms and Conditions, please contact us at <a href="mailto:support@pixlme.com">support@pixlme.com</a>.</p>
    </div>
  );
}

export default TermsAndCondition;

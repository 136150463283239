import  Header from '../Header/Header'
import Footer from '../Footer/index'
function HeaderWrapper({child}){

    return(

        <>
        <Header/>
        {child}
        <Footer/>   
        </>
    );
}

export default HeaderWrapper
import './StepByGuideComponent.css'


function StepByGuide({guideChildren}) {

    const renderGuideSteps = (guideList) => {
        return guideList.map((guide, index) => (
          <div className="stepguide" key={index}>
            <div className="stepguide__header">
              <div className="stepguide__header__count"  style={ {backgroundColor:guide.borderColor} }>{index + 1}</div>
              <p className="role-stepguide__header__title"   style={ {color:guide.borderColor} }>{guide.title}</p>
            </div>

            <div className='role-stepguide__header__content' > <img  src={guide.image} alt="Guide Image"  className="stepguide__card"  style={ {borderColor:guide.borderColor}  } >
         
         </img>
         <div className="role-stepguide__description">{guide.description}</div></div>
           
          </div>
        ));
      };
    
    
        return (
        <div  className="role-guide__section">
    
    <div className="role-guide__header">
    <p className="role-guide__header__title">Step by step guide</p>
            
    
    </div>
    
    
        <div className="role-guide__body">
        
        {  renderGuideSteps(guideChildren) }
    
            </div>
    
    
    {/* <div className='trybutton' >Try Now</div> */}
    
        </div>);
}

export default StepByGuide;

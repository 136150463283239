// Import icons and images
import {CupIcon,TvIcon,GrowthIcon,CollectionIcon,AdjustIcon,GlobalIcon,MonetizeIcon,CommunityIcon} from '../assets/icons';

import { consumerGuide1, consumerGuide2, consumerGuide3 } from '../assets/consumer';
import { keyfeature1, keyfeature2, keyfeature3 ,consumerKeyFeature1,consumerKeyFeature2,consumerKeyFeature3} from '../assets/consumer/keyfeatures';
import {artistKeyFeature1,artistKeyFeature2,artistKeyFeature3}from '../assets/artist/keyfeatures/index';
import { artistGuide1, artistGuide2, artistGuide3 } from '../assets/artist';



// Consumer Benefits Array
const consumerBenefitsArray = [
  {
    icon: CollectionIcon,
    title: "Diverse art collections",
    description: (
      <span>
        Explore diverse collections ranging from
        <br />
        contemporary photography to classic
        <br />
        digital art.
      </span>
    ),
  },
  {
    icon: TvIcon,
    title: "Enhanced home entertainment",
    description: (
      <span>
        Enhance your home entertainment
        <br />
        experience by streaming curated art
        <br />
        collections.
      </span>
    ),
  },
  {
    icon: AdjustIcon,
    title: "Personalized experience",
    description: (
      <span>
        personalize your viewing experience
        <br />
        with our tailored recommendations and
        <br />
        playlist features.
      </span>
    ),
  },
];

// Artist Benefits Array
const artistBenefitsArray = [
  {
    icon: GlobalIcon,
    title: "Global exposure",
    description: "Gain global exposure by showcasing your artwork to a worldwide audience",

  },
  {
    icon: MonetizeIcon,
    title: "Monetization",
    description: "Monetize your creation by selling the digital prints or offering exclusive content",
  },
  {
    icon: CommunityIcon,
    title: "Community engagement",
    description: "Engage with a vibrant community of art enthusiasts and other artists, receive valuable feedback and grow your network",
  },
];

// Artist Opportunities Array
const artistOpportunitiesArray = [
  {
    icon: GlobalIcon,
    title: "Featured Artist Spotlights",
    description: "Get featured on our homepage or in special collections.",
  },
  {
    icon: CupIcon,
    title: "Events and Contests",
    description: "Participate in exclusive events and contests to gain additional exposure.",
  },
  {
    icon: GrowthIcon,
    title: "Feedback and Growth",
    description: "Take advantage of feedback opportunities to refine your craft and grow as an artist.",
  },
];

// Consumer Guide Array
const consumerGuide = [
  {
    title: "Browse and discover",
    description: "Explore featured, trending, and curated art collections.",
    image: consumerGuide1,
    borderColor: "#FF8E3C",
  },
  {
    title: "Select your favorites",
    description: "Save and organize favorite artworks and artists.",
    image: consumerGuide2,
    borderColor: "#D8A53E",
  },
  {
    title: "Stream to TV",
    description: "Seamlessly stream chosen art directly to your TV or other devices.",
    image: consumerGuide3,
    borderColor: "#5CAA3B",
  },
];

// Artist Guide Array
const artistGuide = [
  {
    title: "Upload your art",
    description: "Easily upload high-resolution images.",
    image: artistGuide1,
    borderColor: "#FF8E3C",
  },
  {
    title: "Describe and categorize",
    description: "Add descriptions, tags, and categories for easy discovery.",
    image: artistGuide2,
    borderColor: "#D8A53E",
  },
  {
    title: "Publish and share",
    description: "Display your portfolio on PiXLMe, accessible to a wide audience.",
    image: artistGuide3,
    borderColor: "#5CAA3B",
  },
];

// Artist Key Features Array
const artistKeyFeatures = [
  {
    title: "High quality image support",
    description: "Easily upload and manage your art with our user-friendly interface.",
    image: artistKeyFeature1,

  },
  {
    title: "High quality display",
    description: "Showcase your work in stunning high resolution, ensuring every detail is visible.",
    image: artistKeyFeature2,

  },
  {
    title: "Artists Dashboard",
    description: "Manage your portfolio and track your audience's engagement.",
    image: artistKeyFeature3,
  },
];

// Consumer Key Features Array
const consumerKeyFeatures = [
  {
    title: "Browsing and discovery",
    description: "Easily browse through our featured, trending, and curated collections.",
    image: consumerKeyFeature1,

  },
  {
    title: "Favorite and playlist creation",
    description: "Save your favorite artworks and create personalized playlists.",
    image: consumerKeyFeature2,
   
  },
  {
    title: "2-Click streaming",
    description: "With just two clicks, stream art directly to your TV or other devices, transforming your viewing experience.",
    image: consumerKeyFeature3,
   
  },
];

// Export all arrays
export {
  consumerBenefitsArray,
  artistBenefitsArray,
  artistOpportunitiesArray,
  consumerGuide,
  artistGuide,
  artistKeyFeatures,
  consumerKeyFeatures,
};

import './AppStorebutton.css';
import googleButton from '../../assets/googlebutton.png';
import appStoreButton from '../../assets/appstorebutton.png';

import { playStoreLink, appStoreLink } from './../../constants/linkscontants';

function AppStorebuttons() {
    return (
        <div className='appButtons'>
            <a href={playStoreLink} target="_blank" rel="noopener noreferrer">
                <img src={googleButton} alt='Google Play button' className='appButton' />
            </a>
            <a href={appStoreLink} target="_blank" rel="noopener noreferrer">
                <img src={appStoreButton} alt='App Store button' className='appButton' />
            </a>
        </div>
    );
}

export default AppStorebuttons;

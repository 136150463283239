
import './HomeContest.css'
import  { contestFrame1,contestFrame2,contestImage} from '../../../assets/contest'


function HomeContest(){
    return(<div  className="homeDigitalArt">


        <div className="homeDigitalArt__header"> 
        
      <div  className='homeDigitalArt__header__images '>  
    
      <div className="homeDigitalArt__header__left"><img src={contestFrame1} alt='contest image'  className='homeDigitalArt__header__image'></img> </div>  
    
       
       <div className="homeDigitalArt__header__right"><img src={contestFrame2}  alt='contest image' className='homeDigitalArt__header__image'></img></div>
     </div>
      <div className='homeDigitalArt__header__title__section'> <div  className="homeDigitalArt__header__middle" >
    <p className='homeDigitalArt__header__title'>Biggest Art Streaming Contests</p>
    <p className='homeDigitalArt__header__description'>Enjoy beautiful art from talented creators on<br/>your favorite screens.</p>
<div className='enternow' >Enter Now</div>
    <p className='homeDigitalArt__header__description'>Seats are filling soon</p>

        </div>
        </div>
         </div>
       

       <div className='contestimage__body' > <img  src={contestImage} alt='contest image' className='contestimage' ></img> </div>
    </div>);
}




export default HomeContest

import HomeMainSection from './HomeMainSection/HomeMainSection.jsx'
import PixlmeBenefits from './PixlmeBenefits/PixlmeBenefits.jsx'
import HomeGuide from './HomeGuide/HomeGuide.jsx'
import ShowCase from './HomeShowCase/HomeShowCase.jsx'
import Testimonials from '../../components/Testimonials'
import Features from './Features/Features.jsx'
import HomeContest from './HomeContest/index.js'
import StreamArt from '../../components/StreamArt/index.jsx'
import HomeBenefitsArts from './HomeBenefitsArts/index.js'
import PricingComponent from '../../components/Pricing/index.jsx'


function Home(){
    return (<div className="homepage">
        <HomeMainSection/>

        <PixlmeBenefits/>
        <HomeGuide/>
        <StreamArt/>
        <ShowCase/>
        <Testimonials user="artist"/>
        <Features/>
        <HomeContest/>
        {/* <HomeBenefitsArts/> */}
        <PricingComponent/>
   

    </div>);
}


const LandingSection = () => {
  return (
    <div className="landing-container">
      <div className="text-section">
        <h1>Discover and stream stunning Art on your TV</h1>
        <p>Enjoy beautiful art from talented creators on your favourite screens.</p>
        <button className="get-started-btn">Get started</button>
      </div>
      <div className="image-grid">
        <img src="image1.jpg" alt="Art piece 1" />
        <img src="image2.jpg" alt="Art piece 2" />
        <img src="image3.jpg" alt="Art piece 3" />
        <img src="image4.jpg" alt="Art piece 4" />
        <img src="image5.jpg" alt="Art piece 5" />
      </div>
    </div>
  );
};




export default Home;

import React from 'react';
import './NotFoundPage.css'
const NotFoundPage = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px',height:'78vh' , display:'flex',flexDirection:"column",alignItems:'center',justifyContent:"center"}}>
      <h1>404</h1>
      <h2>Page Not Found</h2>
      <p >The page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFoundPage;

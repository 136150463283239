import './KeyFeatureComponent.css';
import { useState } from 'react';

function KeyFeatureComponent({ keyFeatures }) {
  
  const [activeIndex, setActiveIndex] = useState(0);

  return (
     <div id="features" className="keyFeatureComponent">
      <h2 className="KeyFeatureComponent__title">Key Features</h2>
      <img 
        src={keyFeatures[activeIndex].image} 
        className="keyFeature__previewImage" 
        alt={keyFeatures[activeIndex].title}
      />
      <div className="keyfeatures">
        {keyFeatures.map((feature, index) => (
          <div 
            key={index} 
            className="keyfeaturesCard" 
            onClick={() => setActiveIndex(index)}
          >
            <h3 className="keyfeaturesCard__title">{feature.title}</h3>
            <p className="keyfeaturesCard__description">{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default KeyFeatureComponent;

import './ForConsumerPage.css'
import ForRoleMainSection from '../../components/ForRoleMainSection';
import Testimonials from '../../components/Testimonials';
import Benefits from '../../components/Benefits'
import { consumerBenefitsArray ,consumerGuide,consumerKeyFeatures } from '../../constants/arrayconstants';
import StepByGuide from '../../components/StepByGuideComponent';

import { consumerMainImage } from '../../assets/consumer';
import KeyFeatureComponent from '../../components/KeyFeatureComponent'
import Faq from '../../components/faq';

import PricingComponent from '../../components/Pricing';
import StreamArt from '../../components/StreamArt/index'

function ForConsumerPage(){

    return(<div   className='forConsumerPage'> 

<ForRoleMainSection 
title={<p>Discover and stream<br/>stunning Art on your TV</p>} 
role='For Consumer' 
roleColor="#57D7DF"
content="At PiXLMe, we believe in making beautiful art accessible to everyone. Discover, curate, and stream high-quality art collections to your TV and other display devices, transforming your space into a dynamic art gallery."/>
<img  src={consumerMainImage} alt='consumer home image'  className='forconsumershowcase'></img>
<Benefits title="Benefits" children={consumerBenefitsArray }/>  
<KeyFeatureComponent keyFeatures={consumerKeyFeatures}/>  
<StreamArt/>
<PricingComponent/>
<StepByGuide guideChildren={consumerGuide} />

  <Testimonials user="consumer"/>

  <Faq/>

    </div>)
}

export default ForConsumerPage;
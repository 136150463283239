import './HomeShowCase.css';



import {showCase1,showCase4,showCase5,showCase6,showCase7,showCase8} from '../../../assets/showcase'

function ImageSection({ images, reverse }) {
    return (
        <div
            className='showcase_images_section'
            style={{
                flexDirection: reverse ? 'row-reverse' : 'row',
                marginBottom:"25px"
            }}
        >
            {images.map((image, index) => (
                <img key={index}  src={image.src} className={`showcase_image ${image.className}`} alt={`showcase-${index + 1}`} />
            ))}
        </div>
    );
}

function ShowCase() {
    const section1Images = [
        { src: showCase1, className: 'showcase_first_image' },
        { src: showCase7, className: 'showcase_second_image' },
        { src: showCase8, className: 'showcase_third_image' },
    ];

    const section2Images = [
        { src: showCase6, className: 'showcase_first_image' },
        { src: showCase5, className: 'showcase_second_image' },
        { src: showCase4, className: 'showcase_third_image' },
    ];

    return (
        <div className="showcase__section">
            <p>Showcase</p>
            <ImageSection images={section1Images} />
            <ImageSection images={section2Images} reverse />
        </div>
    );
}

export default ShowCase;

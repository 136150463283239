const playStoreLink='https://play.google.com/store/apps/details?id=com.pixlme&hl=en_IN'
const appStoreLink='https://apps.apple.com/in/app/pixlme-stream-quality-photos/id6667108899'


const artistFlow='https://app.pixlme.com/'



export {playStoreLink,appStoreLink,artistFlow}


import './Pricing.css'


import { playStoreLink,appStoreLink } from '../../constants/linkscontants';
import googleButton from '../../assets/googlebutton.png'
import appStoreButton from '../../assets/appstorebutton.png'
function PricingComponent(){

    return(<div  id='pricing' className='pricing-section'>


<div className='pricing-section__left' >

<div className='pricing-section__left__content'><h1 className='pricing-section__left__title'>Pricing</h1>
<div></div>

</div>
<div><p className='pricing-section__left__description'>Start with a 15-day free trial and explore everything PiXLMe has to offer. Upgrade to our Premium Plan for just $3.99/month for unlimited access to all features and content, including exclusive collections and multi-device streaming.</p>
</div>
</div>
<div className='pricing-section__right' >


    <div className='pricing-section__right__plan-title'>Premium Plan</div>
    <div className='pricing-section__right__plan-price'> <span className='amount'>$3.99/</span>mo</div>
    <div className='pricing-section__right__plan-discount'>Save $60</div>

    <div className='getStarted'>

        <h2 className='getStartedTitle'>Get Started</h2>
        <div className='getStartedTitle__appButtons'>
    <a href={playStoreLink} target="_blank" rel="noopener noreferrer">
        <img src={googleButton} alt='Google Play button' className='getStartedTitle__appButton' />
    </a>

    <a href={appStoreLink} target="_blank" rel="noopener noreferrer">
        <img src={appStoreButton} alt='App Store button' className='getStartedTitle__appButton' />
    </a>
</div>

    </div>
</div>

    </div>)
}

export default PricingComponent;